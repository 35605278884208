import React, {useState} from 'react';
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Translation} from "@autopay.io/translation";
import {constants} from "../../../../../constants";
import {PlateWithStatus} from "../../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../../components/DisplayVehicleCharacteristics";
import {ResponsiveSpacer} from "../../../../../components/ResponsiveSpacer";
import {ButtonContainer} from "@autopay.io/style";

interface NoMatchProps {
    plateId?: string;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const NoMatch = (props: NoMatchProps) => {
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const confirm = () => {
        setConfirmed(true);
        setTimeout(() => props.reset(), constants.TIMEOUT_TIME);
    };

    if (confirmed) {
        return (
            <>
                <PlateWithStatus plateId={props.plateId || ''} statusIcon="ERROR" />
                <ResponsiveSpacer size="sm" />
                {props.vehicleBrand && <DisplayVehicleCharacteristics
                    vehicleBrand={props.vehicleBrand}
                    vehicleModel={props.vehicleModel}
                    vehicleColor={props.vehicleColor}
                />}
                <ResponsiveSpacer size="md" />
                <h1>{Translation.messages().tapnpark.device.error_not_found}</h1>
                <ResponsiveSpacer size="sm" />
                <p>{Translation.messages().tapnpark.device.error_not_found_message}</p>
                <ResponsiveSpacer size="md" />
                <Button color="primary" onClick={props.reset} size="lg">
                    {Translation.messages().tapnpark.device.back}
                </Button>

            </>
        )
    } else {
        return (
            <>
                <h1>{Translation.messages().tapnpark.device.confirm_reg}</h1>
                <ResponsiveSpacer size="lg" />
                <PlateWithStatus plateId={props.plateId || ''} />
                {props.vehicleBrand &&
                    <>
                        <ResponsiveSpacer size={'sm'}/>
                        <DisplayVehicleCharacteristics vehicleBrand={props.vehicleBrand}
                                                       vehicleModel={props.vehicleModel}
                                                       vehicleColor={props.vehicleColor}/>
                    </>
                }
                <ResponsiveSpacer size="md" />
                <ButtonContainer alignment="center">
                    <Button color="secondary" onClick={props.reset} size="lg">
                        {Translation.messages().tapnpark.device.confirm_reg_no}
                    </Button>
                    <Button onClick={confirm} size="lg">
                        {Translation.messages().tapnpark.device.confirm_reg_yes}
                    </Button>
                </ButtonContainer>
            </>
        )
    }
}

export default NoMatch;
