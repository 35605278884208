import React from "react";
import {Icon} from "@autopay.io/style";

type Status = "SUCCESS" | "ERROR";

interface PlateWithStatusProps {
    plateId: string;
    statusIcon?: Status
}

export const PlateWithStatus = ({plateId, statusIcon}: PlateWithStatusProps) => {
    const icon = statusIcon === 'SUCCESS' ? 'check_green' : statusIcon === 'ERROR' ? 'excl_red' : undefined;

    return (
        <div className="vehicle-reg-container">
            {icon && <Icon icon={icon} />}
            <span className="vehicle-reg">{plateId}</span>
        </div>
    );
};

export default PlateWithStatus;
