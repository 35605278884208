import * as React from 'react';
import {SpacerSizes} from "@autopay.io/style/lib/components/Spacer/Spacer";

interface ResponsiveSpacerProps {
    size: SpacerSizes;
}

export const ResponsiveSpacer = ({size}: ResponsiveSpacerProps) => {
    const getMaxSize = () => {
        switch (size) {
            case "lg":
                return '65px';
            case "xl":
                return '100px';
            case "md":
                return '45px';
            case "sm":
                return '35px';
            case "xs":
                return '25px';
            case "xxs":
                return '15px';
        }
    };

    return <div className="responsive-spacer" style={{maxHeight: getMaxSize(), width: getMaxSize()}} />;
};
