import React, { FunctionComponent } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Register from './scenes/register';
import Root from './scenes/root';
import {routes} from "./routes";

const AppRouter: FunctionComponent = () => (
    <BrowserRouter>
        <Switch>
            <Route exact={true} path={routes.REGISTER} component={Register} />
            <Route exact={true} path={routes.ROOT} component={Root} />
        </Switch>
    </BrowserRouter>
);

export default AppRouter;
