import React from 'react';
import {Translation} from "@autopay.io/translation";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Icon} from "@autopay.io/style";
import {ResponsiveSpacer} from "../../../../../components/ResponsiveSpacer";

interface DeviceDeletedProps {
    resetDevice: () => void;
}

const DeviceDeleted = (props: DeviceDeletedProps) => {
    const onResetClick = () => {
        localStorage.clear();
        props.resetDevice();
    }

    return (
        <>
            <Icon icon="excl_red" size={'20%'} />
            <ResponsiveSpacer size="lg" />
            <h1>{Translation.messages().tapnpark.device.error_device_deleted}</h1>
            <ResponsiveSpacer size="md" />
            <Button color="primary" onClick={onResetClick} size="lg">
                {Translation.messages().tapnpark.device.new_device}
            </Button>
        </>
    );
}

export default DeviceDeleted;
