import React from 'react';
import InvalidDevice from "./components/InvalidDevice";
import NoMatch from "./components/NoMatch";
import NetworkError from "./components/NetworkError";
import {ErrorTypes, ValidationAndVehicleInformation} from '../../types';
import DeviceDeleted from "./components/DeviceDeleted";
import UnhandledError from "./components/UnhandledError";
import AlreadyValidated from "./components/AlreadyValidated";
import ConcurrentLimitExceeded from './components/ConcurrentLimitExceeded';

interface ErrorProps {
    type?: ErrorTypes;
    plateId?: string;
    reset: () => void;
    validationInfo: ValidationAndVehicleInformation | null;
}

const Error = (props: ErrorProps) => {
    const {
        type,
        plateId,
        reset,
        validationInfo,
    } = props;

    switch (type) {
        case 'invalidDevice':
            return <InvalidDevice />;
        case 'networkError':
            return <NetworkError reset={reset} />;
        case 'noMatch':
            return (
                <NoMatch
                    plateId={plateId ?? ''}
                    reset={reset}
                    vehicleBrand={validationInfo?.vehicle_brand}
                    vehicleModel={validationInfo?.vehicle_model}
                    vehicleColor={validationInfo?.vehicle_color}
                />
            );
        case 'deviceDeleted':
            return <DeviceDeleted resetDevice={reset} />;
        case 'unhandled':
            return <UnhandledError reset={reset} />;
        case 'alreadyValidated':
            return (
                <AlreadyValidated
                    plateId={plateId ?? ''}
                    reset={reset}
                    vehicleBrand={validationInfo?.vehicle_brand}
                    vehicleModel={validationInfo?.vehicle_model}
                    vehicleColor={validationInfo?.vehicle_color}
                />
            );
        case 'concurrentLimitExceeded':
            return <ConcurrentLimitExceeded reset={reset} />;
        default:
            return <></>;
    }
};

export default Error;
