import {Translation} from "@autopay.io/translation";
import Button from "@autopay.io/style/lib/components/Button/Button";
import React from "react";
import {Icon} from "@autopay.io/style";
import {ResponsiveSpacer} from "../../../../../components/ResponsiveSpacer";

interface ConcurrentLimitExceededProps {
    reset: () => void;
}

const ConcurrentLimitExceeded = (props: ConcurrentLimitExceededProps) => {
    const onResetClick = () => {
        props.reset();
    };

    return (
        <>
            <Icon icon="excl_red" size={'20%'} />
            <ResponsiveSpacer size="md" />
            <h1>{Translation.messages().tapnpark.device.error_concurrent_limit_exceeded}</h1>
            <ResponsiveSpacer size="md" />
            <Button color="primary" onClick={onResetClick} size="lg">
                {Translation.messages().tapnpark.device.reset}
            </Button>
        </>
    );
};

export default ConcurrentLimitExceeded;
