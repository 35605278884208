import React from 'react';
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Translation} from "@autopay.io/translation";
import {Icon} from "@autopay.io/style";
import {ResponsiveSpacer} from "../../../../../components/ResponsiveSpacer";

interface NetworkErrorProps {
    reset: () => void
}

const UnhandledError = (props: NetworkErrorProps) => {
    return (
        <>
            <Icon icon={'excl_red'} size={'20%'} />
            <ResponsiveSpacer size="lg" />
            <h1>{Translation.messages().tapnpark.device.error_unexpected}</h1>
            <ResponsiveSpacer size="lg" />
            <Button onClick={props.reset} size="lg">
                {Translation.messages().tapnpark.device.retry}
            </Button>
        </>
    );
}

export default UnhandledError;
