import React from 'react';
import {DeviceInformation} from "../types";

interface FooterProps {
    deviceInfo: DeviceInformation
}

export const Footer = ({deviceInfo}: FooterProps) => {
    if (deviceInfo.logo && deviceInfo.operator) {
        return (
            <>
                <div className="footer-backdrop">
                    <div/>
                </div>
                <div className="operator-footer">
                    <hr />
                    <img
                        className="pull-right"
                        src={`data:${deviceInfo.logo.contentType};base64,${deviceInfo.logo.data}`}
                        alt="Operator logo"
                    />
                </div>
            </>
        );
    }

    return null;
}

export default Footer;
