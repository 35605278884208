import {Translation} from "@autopay.io/translation";
import Button from "@autopay.io/style/lib/components/Button/Button";
import React from "react";
import PlateWithStatus from "../../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../../components/DisplayVehicleCharacteristics";
import {ResponsiveSpacer} from "../../../../../components/ResponsiveSpacer";

interface AlreadyValidatedProps {
    plateId?: string;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const AlreadyValidated = (props: AlreadyValidatedProps) => {
    const onResetClick = () => {
        props.reset();
    };

    return (
        <>
            <PlateWithStatus plateId={props.plateId || ''} statusIcon="ERROR" />
            <ResponsiveSpacer size="md" />
            {props.vehicleBrand &&
                <>
                    <DisplayVehicleCharacteristics
                        vehicleBrand={props.vehicleBrand}
                        vehicleModel={props.vehicleModel}
                        vehicleColor={props.vehicleColor}
                    />
                    <ResponsiveSpacer size={'md'}/>
                </>
            }
            <h1>{Translation.messages().tapnpark.device.error_already_validated}</h1>
            <ResponsiveSpacer size="md"/>
            <Button color="primary" onClick={onResetClick} size="lg">
                {Translation.messages().tapnpark.device.reset}
            </Button>
        </>
    );
};

export default AlreadyValidated;
